import {
  postcodeValidator,
  postcodeValidatorExistsForCountry,
} from 'postcode-validator';
import * as countries from 'i18n-iso-countries';
import i18next from './i18n';

import validationParser from './validation-parser.js';

export function errorHandler(error) {
  let formErrors = [];
  let inputErrors = {};

  switch (error.response?.status) {
    case 400: // validation error
      inputErrors = validationParser('body', error.response.data.errors);
      formErrors = [error.response.data.message];
      break;

    case 409: // conflict
    case 422: // unprocessable entity - used as secondary validation error
      formErrors = [error.response.data.message];
      break;

    default:
      formErrors = [i18next.t('common:errors.genericRequestFail')];
  }

  return {
    formErrors,
    inputErrors,
  };
}

export function postalCodeValidation(instance) {
  instance.extend({
    locales: {
      en: { postcode: postcodeValidationMessage },
      nl: { postcode: postcodeValidationMessage },
    },
    rules: {
      postcode: ({ value, getGroupValues }, country) => {
        const countryCode = (
          country ||
          getGroupValues()?.country ||
          ''
        ).toUpperCase();
        return postcodeValidatorExistsForCountry(countryCode)
          ? postcodeValidator(String(value).toUpperCase(), countryCode)
          : true;
      },
    },
  });
}

function postcodeValidationMessage({ getGroupValues, args }) {
  const countryCode = (
    args[0] ||
    getGroupValues()?.country ||
    ''
  ).toUpperCase();
  const countryName = countries.getName(countryCode, i18next.resolvedLanguage, {
    select: 'official',
  });
  return i18next.t('formulate:validation.postcode', {
    countryName,
  });
}

export function phoneNumberValidation(instance) {
  instance.extend({
    rules: {
      phoneNumber: ({ value }) => {
        const phoneRegex = /^(\+|00)[1-9][0-9 \-\\(\\)\\.]{7,32}$/;
        return phoneRegex.test(value);
      },
    },
  });
}

export function naturalOrderValidation(instance) {
  const locale = {
    naturallyBefore: ({ args }) =>
      i18next.t('formulate:validation.naturallyBefore', { value: args[0] }),
    naturallyAfter: ({ args }) =>
      i18next.t('formulate:validation.naturallyAfter', { value: args[0] }),
  };

  instance.extend({
    locales: {
      en: locale,
      nl: locale,
    },
    rules: {
      naturallyBefore,
      naturallyAfter,
    },
  });
}

const naturallyBefore = ({ value, getGroupValues }, prop) => {
  if (!prop) {
    throw new Error('Missing prop for naturallyBefore');
  }
  const compareValue = getGroupValues()[prop];
  return compareValue == null ? true : value < compareValue;
};

const naturallyAfter = ({ value, getGroupValues }, prop) => {
  if (!prop) {
    throw new Error('Missing prop for naturallyAfter');
  }
  const compareValue = getGroupValues()[prop];
  return compareValue == null ? true : value > compareValue;
};

export const exportedForTesting = {
  naturallyBefore,
  naturallyAfter,
};
